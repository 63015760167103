/**
*
* BCITO CONTENTS
*
* Config .............. Globally-available variables and config.
*
* Mixins............... Useful mixins.
*
* Type ................ Text sizes, Heirarchy, H1–H6 styles..
*
* Layout .............. Layout modifiers along with vertical and horizontal spacing.
*
* Icons ............... Icons
*
* Themes .............. Themes
*
* Components .......... Components (See below for the component list)
*
* BCITO ............... BCITO specific styles
*
* Utils ............... Util classes and overrides
*
*
*/

@charset "utf-8";


%font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
%font-smoothing-off {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
}

@import "core/config";
@import "core/print";
@import "core/mixins";
@import "core/components/interest-picker";
@import "core/components/blocks";

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%;
    min-height: 100%;
    background-color:$base-bg-color;
    overflow-x: hidden;
}

body {
    font-family:$sans-serif-stack;
    margin: 0;
    background-color: $base-bg-color;
    color: $base-font-color;
    line-height: $base-line-height;
    position: relative;
    min-height: 100%;
    font-size: 16px;
    overflow-x: hidden;
}

button {
  // color: inherit;
  font: inherit;
}

// Remove border when inside `a` element in IE 8/9.
img { border: 0;}

/* Global extends that are handy across the site */

%clearfix {
    /**
     * For IE 6/7 only
     * Include this rule to trigger hasLayout and contain floats.
     */
    *zoom: 1;
}

    %clearfix:before {
        content: " ";
        display: table;
    }

    %clearfix:after {
        clear: both;
        content: " "; /* 1 */
        display: table; /* 2 */
    }

%svg-pattern-base {
    content: "";
    background-size: 100% 100%;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
}

%absolute-fullscreen {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    line-height: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

@import "core/icons";
@import "core/type";
@import "core/layout";
@import "core/themes";
@import "core/util";

/* components */
@import "core/components/wagtail-images";
@import "core/components/buttons";
@import "core/components/banner";
@import "core/components/video-snippet";
@import "core/components/modal";
@import "core/components/lists";
@import "core/components/forms";
@import "core/components/global-nav";
@import "core/components/mobile-nav";
@import "core/components/media";
@import "core/components/trade-tiles";
@import "core/components/subnav";
@import "core/components/news-items";
@import "core/components/sidebar";
@import "core/components/footer";
@import "core/components/register";
@import "core/components/page-category";
@import "core/components/pagination";
@import "core/components/tepukenga";
@import "./node_modules/@octavenz/reoako/dist/reoako.scss";
@import "core/bcito";


// -----------------------------------------------------------------------------
// utilities - handy little things that are great for quickly mocking things up
// -----------------------------------------------------------------------------

.u-hide { display: none; }
.u-show { display: block; }

.u-block {display:block;}
.u-inline-block {display:inline-block;}

.u-flex-fill {flex:1;}

.u-right { float:right; }
.u-left { float:left; }

.u-uppercase { text-transform: uppercase; }

.u-nowrap { white-space: nowrap; }

.img--responsive { max-width:100%; }

.u-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.u-bold { font-weight:bold }
.u-regular { font-weight: 400 }
.u-light { font-weight: 300}

.u-text-right { text-align: right;}
.u-text-left { text-align: left;}
.u-text-center { text-align: center;}

.u-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.u-show--medium,
.u-show--medium-down,
.u-show-small {
    display:none!important;
}

@include small-down {
    .u-text-center--small {  text-align: center; }
    .u-hide--small {  display:none!important; }
    .u-show--small,
    .u-block--small {  display:block!important; }
}

@include smallish-down {
    .u-text-center--smallish {  text-align: center; }
    .u-hide--smallish {  display:none!important; }
    .u-show--smallish,
    .u-block--smallish {  display:block!important; }
}


@include medium-down {
    .u-hide--medium-down { display:none!important; }
    .u-show--medium-down { display:block!important; }
}

a.btn.nav-button-white-border {
    font-size: 14px;
    background-color: transparent;
    border: 1px white solid;
    margin-top: -1px;
}

@include medium {
    .u-hide--medium {  display:none!important; }
    .u-left--medium { float:left; }
    .u-right--medium { float:right; }
}

@include large {
    .u-hide--large {  display:none!important; }
    .u-left--large { float:left; }
    .u-right--large { float:right; }
}

.u-relative {
    position:relative;
}

.u-no-underline {
    text-decoration: none !important;
}

/* states */
.onHover { cursor: pointer; }

.isDisabled {
  cursor: not-allowed;
  color:$mid-grey;
  &:hover {
    color:$mid-grey;
  }
}

.isText {
 cursor: text;
}

// ----------------------------------------------------------------------------
// Handy little pattern
// - removes last childs margin normally used on content blocks :)
// ----------------------------------------------------------------------------
%content-block {
    & > *:last-child {
        margin-bottom:0!important;
    }
}
.content-block {
   @extend %content-block;
}
.bigLink:hover {
    cursor: pointer;
}

.rich-text img {
    max-width: 100%;
    height: auto;
}

.responsive-object {
    position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// ----------------------------------------------------------------------------
// Spacing utilities
// ----------------------------------------------------------------------------

$spacing:   1.5rem;

.u-padding {
    padding: $spacing;
}

.u-pl-l-\@large {
    @include large {
        padding-left: $spacing * 2
    }
}
