/***************************************************************
    News report and news item
****************************************************************/

/***** News reports  ******/

.news-report {
    @include rem-convert(padding-bottom, $space-m);
    &:hover .news-report__link a {
        color:$base-link-hover-color;
    }
}

    .news-report__image-stub {
        position:relative;
        background-color:$light-grey;
        p {
            @include rem-convert(padding, $space-s);
            @include rem-convert(font-size, $font-size-xs);
            text-transform: uppercase;
            font-weight:bold;
            margin:0;

        }
    }

        .news-report__pattern {
            @extend %absolute-fullscreen;

            &:before,
            &:after  {
                @extend %svg-pattern-base;
            }
            &:before {
                @include svgpoly(000000,0.2, 100, 0, 40, 0, 20, 100, 100, 100 );
            }
            &:after {
                @include svgpoly(ffffff,0.3, 100, 100, 100, 90, 0, 60, 0, 100 );
            }
        }

    .news-report__image,
    .news-report__image-stub {
        @include rem-convert(min-height, 200);
    }

/***** News items  ******/

.news-item {
    @extend %content-block;
    @include rem-convert(padding-bottom,42);
    position:relative;
    overflow: hidden;

    .news-item__heading a {
        text-decoration: underline;
    }

    &:hover .news-item__heading a {
        color:$base-link-hover-color;
        text-decoration: none;
    }
}

//more news items macro
.news-item--sub {
    @include rem-convert(padding-bottom,$space-xl);
}
