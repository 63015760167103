body.mobile-menu--visible {
    overflow: hidden;
    height:100%;
     position: fixed;
    .mobile-menu {
        transform: translateX(0%);
    }
}

body.mobile-menu--hidden {
    .mobile-menu {
        transform: translateX(-100%);
    }
}

.mobile-menu {
    position: fixed;
    top: 107px;
    @media screen and (max-width: 718px) {
        top: 56px;
    }
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1100;
    text-align: center;
    transform: translateX(-100%);
    transition: all .5s ease;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    will-change: transform,opacity;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    height: calc(100% + 110px);
    background-color: $dark-grey;
    padding: 3% 0;
    a {
        color: white;
    }
    .main-menu__search {
        margin: 0 0 25px;
        display: block;
    }
}
