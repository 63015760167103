/***************************************************************
    Side navigation and action call out container
****************************************************************/

.sidebar {
    @include rem-convert(margin-top, 64 );
}

.sidebar.sidebar--pardot-form {
    margin-top: 0;
}

.action-container {
    @include rem-convert(padding-left, $space-m);
    @include rem-convert(padding-right, $space-m);
    @include rem-convert(padding-top, $space-l);
    @include rem-convert(padding-bottom, $space-l);

    // Reset the gutter spaces to have a full-width component.
    @include smallish-down {
        margin-left: -$gutters--small;
        margin-right: -$gutters--small;
    }
}

.side-nav-container {
    background-color:$light-orange;
    border-top: 3px solid $orange;

}
    //this is used when there is no list item and you
    //just want to use copy it is also used in action
    //container to keep the size consistent
    .side-nav__content {
        p {
            @include rem-convert(font-size, $font-size-m );
        }
    }

.side-nav-container,
.action-container {

    @include large {
        padding: 8%;
    }
}
    .side-nav__item a {

        color:$base-font-color;
        &.active {
            color:$base-font-color;
            font-weight:bold;
            @include rem-convert(font-size, $font-size-m);
        }

    }

    .side-nav {

        //if the parent heading is active don't indeed the children
        .section-heading > .side-nav.active-children {
            margin-left:0;
        }
        //section heading is hidden by default
        .section-heading {
            display:none;
            &.active {
                display:block;
            }
        }

        display:none;
        padding:0;
        list-style-type: none;
        margin:0;
        line-height: 1.35;

        .side-nav {
            @include rem-convert(margin-bottom, $space-m);// should only target nested lists
        }
            .side-nav__item {
                margin-top: 0; // remove default
            }

        &.active {
            display:block;
        }
        &.active-children {
            @include rem-convert(margin-left, $space-m);
            display:block;
        }

        a {
           @include rem-convert(font-size, $font-size-s);
           display: block;
           padding: 0.5em 0;
           &:hover {
	           color: $blue;
           }
        }

        &.active {
            .side-nav.active {
               .side-nav.active {
                 @include rem-convert(margin-left, $space-l);
                }
            }
        }

        //this should overide everythign even active  links

    }

    .side-nav-container .section-heading,
    .side-nav-container .section-heading > a {
        color:$orange;
        @include rem-convert(font-size, 21);
    }
    .side-nav-container p.section-heading,
    .side-nav-container .section-heading > a:first-of-type {
        font-weight: bold;
    }

    //todo fix this headings don't work
    // make the current item not look or beahve linky
    .side-nav .active > a:hover {
	    color: inherit;
	    //cursor: default;
    }
