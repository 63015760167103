.register__block + .register__block {
    @include smallish-down {
        @include rem-convert(margin-top, $space-l);
    }
}

.register__content {
    @include rem-convert(padding-top,$space-xl);
    @include rem-convert(padding-bottom,$space-xl);

    @include smallish {

    }
}

.register__heading {
    @include rem-convert('font-size', 16);
    margin: 0;
    line-height: 1.3;

    @include smallish {
        @include rem-convert('font-size', 20);
    }

    @include medium {
        @include rem-convert('font-size', 24);
    }

    color: $white;
}

.register__cta {
    padding-left: 1.5em;
    padding-right: 1.5em;
    @include rem-convert(line-height,$space-xxxxl);
    @include rem-convert(height,$space-xxxxl);
    @include rem-convert(margin-top,$space-xxxl);
    @include rem-convert(margin-bottom,$space-xxxl);
    display:inline-block;
    box-sizing:border-box;
    text-align:center;
    border: 1px solid inherit;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.register__cta--last {
    @include rem-convert(margin-top,$space-xxxl);
    margin-bottom: 0;
}

.register__secondary-icon {
    margin: 0 auto;

    @include medium {
        float: left;
        padding-right: 1em;
    }
}

.register__secondary-body {
    @include smallish-down {
        margin-top: 1em;
    }

    @include medium {
        overflow: hidden;
    }
}

.tv-banner {
    overflow: hidden;
}

.tv-banner__icon {
    @include smallish-down {
        display: block;
        margin: 0 auto;
    }

    @include smallish {
        display: inline-block;
        margin-right: 2em;
    }
}

.tv-banner__strapline {
    font-weight: normal;

    @include smallish {
        display: inline-block;
    }
}

.tv-banner__link {
    font-weight: bold;
    position: relative;

    &:hover {
        color: $white;
        text-decoration: none;
    }

    &::after {
        content: ' ';
        display: block;
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        border-bottom: 3px solid $white;
    }
}

.tv-banner__cta-block {
    position: relative;

    padding-bottom: 20%;

    @include smallish {
        padding-bottom: 15%;
    }

    @include large {
        padding-bottom: 0;
    }
}

.tv-banner__cta {
    position: absolute;
    bottom: -20px;
    transition: bottom 0.1s linear;

    &:hover {
        bottom: -10px;
    }
}
