/***************************************************************
   Footer
****************************************************************/

.footer {
    @extend %clearfix;
    background-color:#323232;
    color:$white;
    .footer__section-heading {
        @include rem-convert(margin-bottom, $space-s);
    }
    .footer__section-heading a {
        color:$white;
    }

    .footer__contact-address,
    .nav__link,
    .nav__contact-link {
        @include rem-convert('font-size', $font-size-s);
        color: rgba(255,255,255,0.7); // scope to a variable??
    }

    ul {
        @include rem-convert('margin-bottom', $space-m);
    }

        .nav__link {
            display:block;
        }
}

.footer--content { @extend %clearfix; }

.footer__contact-info {
    float:right;
    span {
        display: inline-block;
    }
    a {
        display: block;
        &.btn--footer-news {
            max-width: 200px;
        }
    }

    .footer__contact-info--socials {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 1rem;

        .icon-nav__item:first-of-type {
            .icon-nav__link {
                padding-left: 0;
            }
        }

        .icon-nav__item {
            margin: 0;

            .icon-nav__link {
                padding: 0.3rem;

                &:hover {
                    .icon--linkedin {
                        opacity: 0.8;
                        color: #fff;
                    }
                }
            }

            .icon-nav__link svg,
            .icon-nav__link .i {
                width: 1.5rem;
                height: 1.4rem;
                display: block;
            }
            .icon-nav__link .icon--linkedin {
                height: 1.3rem;
                width: 1.4rem;
            }
            .icon-nav__link .i-youtube-icon {
                height: 2.2rem;
                width: 2.1rem;
                margin-top: 3.5px;
            }
        }
    }
}

.footer__site-map {
    float:right;
    @include smallish-down {
        float:left;
        margin-top: 1.5rem;
    }
    ul {
        margin-bottom:2em;
    }
}


