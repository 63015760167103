/***************************************************************
   Forms
****************************************************************/


//LAURA TO TIDY AS ITS PRETTY MESSY ;(
//
// --------------------------------------------------
//make sure these are the same as the buttons
//input size
$input--size:   40;

//input colours
$input-bg:                       #fff;
$input-bg-disabled:              #ccc;
$input-color:                    $base-font-color;
$input-border:                   $light-grey;
$input-border-radius:            4;
$input-border-focus:             #66afe9;
$input-placeholder:              $base-font-color;


//validation states
$state-success-text:             #3c763d;
$state-success-bg:               #dff0d8;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%);

$state-error-text:              $form-error;
$state-error-bg:                $form-error;
$state-error-border:            $form-error;


form {
  width:auto;
}

fieldset {
  display:none; //hidden by default

  .form-update & {
    display:block;
  }

  padding: 0;
  margin: 0;
  border: 0;
  // Chrome and Firefox set a `min-width: min-content;` on fieldsets,
  min-width: 0;
  h5 {
    @include rem-convert(margin-bottom, $space-l);
  }
  &.active {
    display:block;
  }
}

label {
  @include smallish-down {
    @include rem-convert(margin-bottom, $space-xxs);
  }
  display: inline-block;
  max-width: 100%; // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
  padding-top:0;
}


// Normalize form controls
//
input[type="search"] {
  box-sizing:border-box;
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9; // IE8-9
  line-height: normal;
}

// Set the height of file controls to match text inputs
input[type="file"] {
  display: block;
}

// Make range inputs behave like textual form controls
input[type="range"] {
  display: block;
  width: 100%;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
  height: auto;


}

// -----------------------------------------------------------------------------
// Select styles
// -----------------------------------------------------------------------------

select {
  background: transparent;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border-radius: 0;
  // This line has a syntax error in it so I've commented it out for now.
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='6' viewBox='0 0 12 6' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath id='path-1' d='M12 10l8 8h-8z'/%3e%3c/defs%3e%3cg id='Symbols' fill='none' fill-rule='evenodd'%3e%3cg id='Icons/-down-select' transform='translate(-10 -14)'%3e%3cmask id='mask-2' fill='%23fff'%3e%3cuse xlink:href='%23path-1'/%3e%3c/mask%3e%3cuse id='Rectangle-14' fill='%23D8D8D8' fill-rule='nonzero' transform='rotate(-45 16 14)' xlink:href='%23path-1'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  color: $base-font-color;
  border-radius: 4px;
}

select::-ms-expand {
    display: none;
}

// Focus for file, radio, and checkbox
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.field--error {
  margin-bottom:0;
  @include smallish-down {
    @include rem-convert(margin-bottom,$space-xxs);
  }
  @include rem-convert(font-size,$font-size-s);
}

// Common form controls
//
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
//
// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]

.form-control,
.field_select,
.text {
  @include rem-convert(font-size, $font-size-m);
  @include rem-convert(padding-left, $space-xs);
  @include rem-convert(padding-right, $space-xs);
  @include rem-convert(border-radius,$input-border-radius);
  @include rem-convert(height, $input--size);
  @include rem-convert(padding-top, $space-xs);
  @include rem-convert(padding-bottom, $space-xs);
  @include rem-convert(margin-bottom, $space-xxs);
  @include smallish-down {
    @include rem-convert(margin-top, $space-xs);
  }
  display: block;
  width: 100%;
  color: $input-color;
  background-color: $input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid $input-border;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  font-family: inherit;

  // Customize the `:focus` state to imitate native WebKit styles.
  $color-rgba: rgba(red($input-border-focus), green($input-border-focus), blue($input-border-focus), .6);
  &:focus {
    border-color: $input-border-focus;
    outline: 0;
    box-shadow:inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba;
  }

   // Placeholder
  &::-moz-placeholder           { color: lighten($input-placeholder, 40%); }  // Firefox                               opacity: 1; } // See https://github.com/twbs/bootstrap/pull/11526
  &:-ms-input-placeholder       { color: lighten($input-placeholder, 40%); } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: lighten($input-placeholder, 40%); } // Safari and Chrome

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    cursor: not-allowed;
    color: $input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content
  }


}

// Reset height for `textarea`s
textarea.form-control {
  height: auto;
  @include rem-convert(padding-top, $space-xs);
  @include rem-convert(padding-bottom, $space-xs);
}


// Search inputs in iOS
//
// This overrides the extra rounded corners on search inputs in iOS so that our
// `.form-control` class can properly style them. Note that this cannot simply
// be added to `.form-control` as it's not specific enough. For details, see
// https://github.com/twbs/bootstrap/issues/11586.

input[type="search"] {
  -webkit-appearance: none;
}


// Special styles for iOS temporal inputs
//
// In Mobile Safari, setting `display: block` on temporal inputs causes the
// text within the input to become vertically misaligned.
// As a workaround, we set a pixel line-height that matches the
// given height of the input. Since this fucks up everything else, we have to
// appropriately reset it for Internet Explorer and the size variations.

//todo
// input[type="date"],
// input[type="time"],
// input[type="datetime-local"],
// input[type="month"] {
//   $line-height-base:3;
//   line-height: $input-height-base;
//   // IE8+ misaligns the text within date inputs, so we reset
//   line-height: $line-height-base #{\0};
// }


// Form groups
.form-group {
    @include rem-convert(padding-bottom, $space-m);
    @include rem-convert(padding-top, $space-m);
    border-bottom:1px solid $primary-border-color;
    &.form-group--submit {
        border-bottom:none;
    }
}

.page-authenticate {
    & .form-group {
        border-bottom:none;
    }
    & .form-group--submit {
        padding-top: 0;
    }
}


// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.radio,
.checkbox {
  position: relative;
  display: block;
  label {
    @include rem-convert(padding-left, 20);
    margin-bottom: 0;
    //font-weight: normal;
    cursor: pointer;
  }
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; // Move up sibling radios or checkboxes for tighter spacing
}


// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; // space out consecutive inline controls
}

// Apply same disabled cursor tweak as for inputs
// Some special care is needed because <label>s don't inherit their parent's `cursor`.
//
// Note: Neither radios nor checkboxes can be readonly.
input[type="radio"],
input[type="checkbox"] {
  &[disabled],
  &.disabled,
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}
// These classes are used directly on <label>s
.radio-inline,
.checkbox-inline {
  &.disabled,
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}
// These classes are used on elements with <label> descendants
.radio,
.checkbox {
  &.disabled,
  fieldset[disabled] & {
    label {
      cursor: not-allowed;
    }
  }
}



// Help text
//
// Apply to any element you wish to create light text for placement immediately
// below a form control. Use for general help, formatting, or instructional text.

.help-block,
.label-note {
    @extend .small;
    display: block; // account for any element using help-block
    //margin-top: 5px;
    margin-bottom: 0;
    color: lighten($base-font-color, 20%); // lighten the text some for contrast
}
.help-block {
  @include smallish-down {
    margin-bottom: 5px;
  }
}
.input-text {
    display: block;
    margin-bottom: 0;
    @include smallish-down {
        margin-top: -2px;
    }
}

.input--disabled {
    box-shadow:none!important;
    border:none!important;
    padding-left:0;
}


// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).
//
// Heads up! This is mixin-ed into `.navbar-form` in navbars.less.

.form--inline-field {
    // Kick in the inline
    @include medium {

      min-height: 4.5em;

      .form-def {
          padding-top: .6em;
      }
      &.form--has-helper .form-def {
          padding-top: 0;
      }
      .form-item-padding {
          position: relative;
      }
      .form-field > .text ,
      .form-field > .field_select {
          position: absolute;
          top: 0;
          right: 0;
          width: 50%;
      }
      &.form--has-helper .form-field > .text,
      &.form--has-helper .form-field > .field_select {
          top: .2em;
      }
      .label-note {
          margin-top: 0;
      }
    }
}


.form--inline {

    @extend %clearfix;

  // Kick in the inline
  @include medium {

     & + .form--inline {margin-top:0;}
    @include rem-convert(padding-top, $space-m);
    @include rem-convert(padding-bottom, $space-m);
    margin:0;

    // Inline-block all the things for "inline"
    * {
      vertical-align: middle;
    }
    //wrapper
    .form-group {
      display: inline-block;
      vertical-align: middle;

    }

    // In navbar-form, allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      vertical-align: middle;
      width:100%;
    }
    //label elements
    .control-label,label {
      margin-bottom: 0;
      vertical-align: middle;
    }
    label { @include rem-convert(padding-right, $space-m);}
   .help-block {display:inline-block;margin-top:0;}
    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match (which also avoids
    // a bug in WebKit: https://github.com/twbs/bootstrap/issues/1969).
    .radio,
    .checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;

      label {
        padding-left: 0;
      }
    }

    .radio input[type="radio"],
    .checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0;
    }
  }
}


.input--group {
  .form-control {
    border-top:none;
    border-radius:0;
  }

  .form-control:first-of-type {
    @include rem-convert(border-top-left-radius,$input-border-radius);
    @include rem-convert(border-top-right-radius,$input-border-radius);
    border-top: 1px solid $input-border;
  }
  .form-control:last-child {
    @include rem-convert(border-bottom-left-radius,$input-border-radius);
    @include rem-convert(border-bottom-right-radius,$input-border-radius);
    border-bottom: 1px solid $input-border;
  }
}


// // Feedback states
// .has-success {
//   @include form-control-validation($state-success-text, $state-success-text, $state-success-bg);
// }

// .has-error {
//   @include form-control-validation($state-error-text, $state-error-text, $state-error-bg);
// }

.form--sub-header {
  @include rem-convert(margin-top, $space-xxxl);
}

.error {
  //label,
  //.waf-help-text,
  .waf-error-message,
  .form-error {
    color:$state-error-text;
  }

  .text {
    border-color: $state-error-text;
    box-shadow:inset 0 1px 1px rgba(0,0,0,.075); // Redeclare so transitions work
    &:focus {
      border-color: darken($state-error-text, 10%);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($state-error-text, 20%);
    }
  }
  .error-msg {
    margin-top:0;
    @include rem-convert(font-size, $font-size-s);
    color:$state-error-text;
  }

  .error-msg a {
    color:$state-error-text;
    text-decoration: underline;
  }


  &.error-fix .error-msg {
    @include rem-convert(padding-left, $space-m);
  }

  .waf-radio + label:before {
      border-color: $state-error-text;
  }

  .waf-checkbox + label:before {
      border-color: $state-error-text;
  }
}

//todo move later
.form-error {
  @include rem-convert(font-size, $font-size-s);
}
//should put horizontal__input on each input in horizontal-inputs
.horizontal-inputs {
  .horizontal__input {
    margin-top:0;
    width:100%;
  }

  .form-item:first-of-type .horizontal__input,
  .form-item:first-of-type .text {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .form-item + .form-item {
    .horizontal__input,
    .text {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left:none;
    }
  }
}
  .horizontal__input {
    @include rem-convert(border-radius, $input-border-radius);
  }

.stacked-inputs {

    .form-item label {
      display:none;
    }
    .text {
      //remove default styling
      margin:0;
      border-bottom:none;
      border-radius: 0;
    }
    .form-item:first-of-type .text {
      border-bottom:none;
      @include rem-convert(border-top-left-radius, $input-border-radius);
      @include rem-convert(border-top-right-radius, $input-border-radius);
    }
    .form-item:last-child .text {
      @include rem-convert(border-bottom-left-radius, $input-border-radius);
      @include rem-convert(border-bottom-right-radius, $input-border-radius);
      border-bottom:1px solid $input-border;
    }
    .form-item:last-child select.text {
      //fixes a chrome bug

      -webkit-appearance: none;
    }
}

.form--spacing {
  @include rem-convert(margin-bottom, $space-m);
}



  .segmented-control {
    @extend %clearfix;
    float:right;
    list-style: none;
    padding-left: 0;
    margin-bottom:0;

    & > li {
      &:first-of-type label {
        @include rem-convert(border-top-left-radius, $input-border-radius );
        @include rem-convert(border-bottom-left-radius, $input-border-radius );
      }
      &:last-child label {
        @include rem-convert(border-top-right-radius, $input-border-radius );
        @include rem-convert(border-bottom-right-radius, $input-border-radius );
      }

      display:inline-block;
      margin:0;
      float:left;
      position:relative;
      margin-right: -1px;
      margin-bottom: -1px;

    }

    input {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
    }

    label {
      border:1px solid $input-border;
      cursor: pointer;
      text-align:center;
      color: $input-color;
      @include rem-convert(height, $input--size);
      @include rem-convert(padding-top, $space-xs);
      @include rem-convert(padding-bottom, $space-xs);
      @include rem-convert(padding-left, $space-s);
      @include rem-convert(padding-right, $space-s);
      display: inline-block;
      span {
        display:block;
        cursor:pointer;
      }
    }
    input:checked + label {
      //background: $base-link-color;
      background-color: $base-link-color;
      color:$white;
      border-color: $base-link-color;
    }
    // input:focus + label {
    //   outline: solid 3px $base-link-hover-color;
    // }
  }
  .segmented-control--checkbox {
    > li {
      margin-right: .5em;
    }
    input:checked + label {
  // border-left: 1px solid darken($base-link-color, 5%);
  }
}



/**
 * Checkbox field, uses a custom icon for the checkmark
 */
.field-checkbox-container {
  @include rem-convert(margin-top, $space-m);
  padding:0;
}

.field-checkbox {
  max-width:20%;
}

  .field-checkbox__box {
    width: 1.25em;
    height: 1.25em;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    //margin-right: .5em;
    margin-top: -.25em;
  }

  .field-checkbox__icon,
  .field-checkbox__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .field-checkbox__input {
    opacity: 0;
    z-index: 2;
    &:hover {
      cursor: pointer;
    }
  }

  .field-checkbox__input:checked + .field-checkbox__icon {
    background-position: 0 100%;
  }

  // .field-checkbox__input:focus + .field-checkbox__icon {
  //   outline: solid 3px $base-link-hover-color;
  // }

  .field-checkbox__label {
      cursor: pointer;
      font-weight:300;
      max-width:80%;
      margin-left:0.5em;
      .error & {
        color:$base-font-color;
      }
  }


.errorlist {
  padding:0;
  list-style: none;
  margin-top:0;
  li {
    @include rem-convert(font-size, $font-size-s);
    color:$form-error;
  }
}
.btn-container {
  @extend %clearfix;
  [data-previous] {
    float:left
  }
  [data-next],
  [data-submit] {
    float:right
  }
}

.hidden-form-intro {
  h1 {
    margin-bottom:0;
  }
}

.confirmation-checkbox {
  @extend %clearfix;
  .field-checkbox__box {
    float:left;
    @include rem-convert(margin-top, $space-s);
  }
  label {
    max-width:80%;
    cursor: pointer;
    @include rem-convert(margin-left, $space-m);
    float:left;
  }
}

.fieldset-error {
    .form-item {
      display:none;
      &.error {
          display: block;
          width:100%;
      }
    }
    .horizontal-inputs .error .text {
        border:1px solid $form-error;
        @include rem-convert(border-radius, $input-border-radius);
    }
}

.radio-group + .radio-group {
    @include rem-convert(margin-top, $space-xs);
}

  .radio-group__input {
      float: left;
      padding-right: 1em;
      display: block;
  }

  .radio-group__label {
      overflow: hidden;
      display: block;
      padding-left: 1em;
  }


.waf-notification {
  background-color: #FEF8F8;
  border-left: 4px solid #F24949;
  padding: 28px 24px;
  margin: 32px 0;
}

.waf-radio {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 42px;
      display: inline-block;
    }

    // Box.
    & + label:before {
        content: '';
        margin-right: 15px;
        display: inline-block;
        vertical-align: text-top;
        width: 27px;
        height: 27px;
        background: transparent;
        transition: all .1s ease-in;
        border: 2px solid #ccc;
        border-radius: 50%;
        //margin-top: -2px;
        position: absolute;
        left: 0;

    }

    // Box focus
    &:focus + label:before {
        box-shadow: 0 0 0 5px rgba(238, 240, 239, .5);
    }

    // Box checked
    &:checked + label:before {
        background: #009EC9;
        border: 2px solid #0082A6;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        top: 13px;
        left: 7px;
        background: #fff;
        width: 3px;
        height: 3px;
        box-shadow:
          3px 0 0 #fff,
          5px 0 0 #fff,
          5px -3px 0 #fff,
          5px -5px 0 #fff,
          5px -8px 0 #fff,
          5px -11px 0 #fff;
        transform: translate3d(0, 0, 0) rotate(45deg);

    }

}



// -----------------------------------------------------------------------------
// checkbox  with label
// -----------------------------------------------------------------------------

.waf-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label,
    .checkbox-label {
        position: relative;
        cursor: pointer;
        padding: 0;
        padding-left: 42px;
        display: inline-block;
    }

    // Box.
    & + label:before,
    .checkbox-label:before {
        content: '';
        margin-right: 15px;
        display: inline-block;
        vertical-align: text-top;
        width: 27px;
        height: 27px;
        background: transparent;
        transition: all .1s ease-in;
        border: 2px solid #ccc;
        position: absolute;
        left: 0;
    }

    // Box focus
    &:focus + label:before {
        box-shadow: 0 0 0 5px rgba(238, 240, 239, .5);
    }

    // Box checked
    &:checked + label:before {
        background: #009EC9;
        border: 2px solid #0082A6;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        top: 13px;
        left: 7px;
        background: #fff;
        width: 3px;
        height: 3px;
        box-shadow:
          3px 0 0 #fff,
          5px 0 0 #fff,
          5px -3px 0 #fff,
          5px -5px 0 #fff,
          5px -8px 0 #fff,
          5px -11px 0 #fff;
        transform: translate3d(0, 0, 0) rotate(45deg);
    }

}

.waf-form {
  @include rem-convert('margin-bottom', $space-xxxxl);

}

.waf-field-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.waf-field-label-choice {
  font-weight: normal;
}

.waf-choice-inline {
  display: inline;
  margin-right: 56px;
}

.waf-fieldset {
  @include rem-convert('margin-top', $space-xxxxl);
  @include rem-convert('margin-bottom', $space-xxxxl);
}

.waf-help-text {
    color: lighten($base-font-color, 20%); // lighten the text some for contrast
    @include rem-convert('font-size', $font-size-s);
    @include rem-convert('margin-top', $space-xxs);
    @include rem-convert('margin-bottom', $space-xl);
}

.waf-fieldset {
  .field_select, .text {
    padding-left: 17px;
    height: 50px;
  }

  .form-control.text {
    height: auto;
  }

  select {
    background: transparent;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    border-radius: 0;
    // This line has a syntax error in it so I've commented it out for now.
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='12' viewBox='0 0 21 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.5 11L20 1' stroke='%23009EC9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) 50%;
    color: $base-font-color;
    border-radius: 4px;
  }

  select::-ms-expand {
      display: none;
  }
}

.waf-checkbox-container {
  height: 50px;
}

.waf-form {
  // Show the required asterix next to labels of inputs that have required attribute
  .waf-field-label--required:after {
    content: " *";
    color: $blue;
    margin-left: 1px;
  }
}
