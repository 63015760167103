/***************************************************************
   List helpers
****************************************************************/

/*--------------Inline lists------------*/
.nav,
.list--inline {
    padding:0;
    margin: 0;
    & > li {
        display: inline-block;
        margin-top:0;
    }
}

/* this is used to space out nav items nicely */
.nav--links {
    & > li {
        @include rem-convert(margin-right, $space-l);
        line-height: 1;
        &:last-child {
            margin-right: 0;
        }
    }
}

/*--------------Unstyled lists------------*/

%list-unstyled {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
}

.list-unstyled,
.form-item ul {
    @extend %list-unstyled;
}

/*--------------Styled lists (the default is a dot)------------*/

$list-outdent: -1em;
.list-styled{
   @extend %list-unstyled;

    & > li {
        margin-left: 0;
        position: relative;
    }
    & > li:before {
        content: "• ";
        color: $base-font-color;
        position: absolute;
        margin-left: $list-outdent;
        background-size: cover;
    }
}

.rich-text ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    & > li {
        margin-left: 0;
        position: relative;
        padding-left:1em;
    }
    & > li:before {
        content: "• ";
        color: $base-font-color;
        position: absolute;
        margin-left: $list-outdent;
        background-size: cover;
    }
}

    /* modifier */
    .list-styled--arrow {
        margin-left: 0;
        & > li {
            padding-left: 1.25em;
        }
        & > li:before {
            @include rem-convert(font-size, $font-size-m);
            @include rem-convert(width, 8);
            @include rem-convert(height, 11);
            content: "";
            position: absolute;
            margin-left: -1em;
            margin-top: .4em;
            background-size: cover;
            background-position: 0 $icon-bg-black;
        }

        a.u-color-orange:hover {
            color:darken($orange, 7%);
        }

        a.u-color-white:hover {
            color:darken($white, 7%);
        }
    }


.icon-nav {
    opacity: 1;
    line-height: 0;
    margin-top:0.5em;
    transition: opacity .25s ease-out;
    .ie8 & {
        filter: none;
    }
}

.icon-nav__item {
    display:inline-block;
    // font-size: px-to-rem(12); not working, rendering this string to the FE
    line-height: 1.33333333;
    text-transform: uppercase;
    font-weight: bold;
}

.icon-nav__item + .icon-nav__item {
    margin-left: 1em;

    .icon-nav--hidden & {
        margin-left: .25em;
    }
    //transition: margin-left .25s cubic-bezier(0.175, 0.885, 0.32, 1.275); // it’s an easeOutBack
}


.icon-nav__link {
    display: inline-block;

    &:hover > .i {
        opacity: .8;
    }
    .ie8 &:hover > .i {
        filter: none;
    }
}

.subscribe .form-item ul label {
    font-size: medium;
    font-weight: normal;
}
