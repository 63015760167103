@use 'sass:math';

@function remify($pxval) {
   @return #{math.div($pxval, 16)}rem;
}

@mixin rem-convert($prop, $pxval) {
   // #{$prop}: #{$pxval}px;
    #{$prop}: remify($pxval);
}

@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
  // Color the label and help text

  .control-label,
  .radio,
  label,
  .checkbox,
  .field--error,
  .radio-inline,
  .checkbox-inline  {
    color: $text-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $border-color;
    box-shadow:inset 0 1px 1px rgba(0,0,0,.075); // Redeclare so transitions work
    &:focus {
      border-color: darken($border-color, 10%);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($border-color, 20%);
    }
  }
}

// don't use a hash on the fill hex (has to be url encoded for IE)
 @mixin svgpoly($fill, $fill-opacity, $sw-x, $sw-y, $nw-x, $nw-y, $ne-x, $ne-y, $se-x, $se-y ) {
    background-repeat:no-repeat;
    background-image: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%22100px%22%20height%3D%22100px%22%20viewBox%3D%220%200%20100%20100%22%20enable-background%3D%22new%200%200%20100%20100%22%20xml%3Aspace%3D%22preserve%22%20preserveAspectRatio%3D%22none%22%20%3E%0D%0A%3Cpolygon%20fill%3D%22%23#{$fill}%22%20opacity%3D%22#{$fill-opacity}%22%20points%3D%22#{$sw-x}%2C#{$sw-y}%20#{$nw-x}%2C#{$nw-y}%20#{$ne-x}%2C#{$ne-y}%20#{$se-x}%2C#{$se-y}%20%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A');
 }


// a save way to return the nth value of a list
@function save-nth-list-value($list, $position) {
    $value: null;
    @for $i from 1 through length($list) {
        @if $i == $position {
            $value: nth($list, $i);
        }
    }
    @return $value;
}

