/*------------------------------------*\
    #PAGE LAYOUT
\*------------------------------------*/


// ----------------------------------------------------------------------------
// Main site wrapper
// ----------------------------------------------------------------------------
$gutters--small: 0.5em;
.gutters {
    @include rem-convert(max-width, 1200);
    margin: 0 auto;

    @include smallish-down {
        padding:0 $gutters--small;
    }
}

// ----------------------------------------------------------------------------
// column info
// ----------------------------------------------------------------------------
$column--large: 30;
$column--small: $space-s;//12

.col {
    @include rem-convert(padding-left, $column--large);
    @include rem-convert(padding-right, $column--large);
    @include smallish-down {
        @include rem-convert(padding-left, $column--small);
        @include rem-convert(padding-right, $column--small);
    }
}

    @include small-down {
        .col--remove-small {
            padding-left:0;
            padding-right:0;
        }
    }
    /* Internal gutters in this case it's more content gutters */
    $internal-gutter: 16;
    $internal-gutter--small: 10;

    .grid--internal {
        @include rem-convert(margin-left, -$internal-gutter);
        @include rem-convert(margin-right, -$internal-gutter);
        .col {
            @include rem-convert(padding-right, $internal-gutter);
            @include rem-convert(padding-left, $internal-gutter);

        }
        @include smallish-down {
            @include rem-convert(margin-left, -$internal-gutter--small);
            @include rem-convert(margin-right, -$internal-gutter--small);
            .col {
                @include rem-convert(padding-right, $internal-gutter--small);
                @include rem-convert(padding-left, $internal-gutter--small);

            }
        }
    }

    /* this column is used for secondary data */
    .col--secondary {
	    margin-right: 4%;

	    @include xlarge {
	        margin-right: 9%;
	    }
	    @include xxlarge {
	        margin-right: 11%;
	    }
    }

// ----------------------------------------------------------------------------
// Grid
// ----------------------------------------------------------------------------


 .grid {
     font-size: 0;
     .intro .rich-text > &, .intro > & {
         font-size: 0;
     }

     & > * {
         display:inline-block;
         font-size: medium;
         vertical-align: top;
     }

     .-gutters-off {
        > * {
            padding: 0;
        }
     }

     &.-row-spacing.-row-spacing { /*  make sure this is really, really important ;) */
         margin-bottom: -#{$space-m}px !important;
         & > * {
             margin-bottom: #{$space-m}px;
         }
     }
}

     .grid-flex {
         font-size: 0;
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         align-items: stretch;

         & > * {
             display:inline-block;
             font-size: medium;
             vertical-align: top;
             display: flex;
             flex-direction: column;
         }
     }

    .grid--justify {
       text-align: justify;
       text-justify: distribute-all-lines;
       font-size: 0;

       & > * {
           display:inline-block;
           text-align: left;
           float: none;
           font-size: medium;
           vertical-align: top;
       }

       &:after {
           display:inline-block;
           height: 0;
           width: 100%;
           content: '';
       }
    }

    .grid--reverse-mobile {
        @include medium-down {
            display: flex;
            flex-direction: column-reverse;
            & .col:first-child > .section {
                padding-top: 0;
            }
        }
    }

    .grid--centre-smallish-down {
        @include smallish-down {
            display: block;
            text-align: center;
            margin-left:auto;
            margin-right:auto;

        }
    }

    //grid modifiers
    .grid--reverse {
        direction: rtl;
        & > * {
            direction: ltr;
        }
    }
    
    .small-grid-bottom {
        & > * {
            vertical-align: middle;
        }
        @include smallish-down {
            & > * {
                vertical-align: bottom;
            }
        }
    }
    
    .grid--center { text-align:center; }

    .grid--right { text-align:right; }
    
    .grid--left { 
        text-align: left !important; 
    }

    .grid--middle {
       & > * { vertical-align: middle; }
    }

    .grid--bottom {
        & > * { vertical-align: bottom; }
    }

    .grid {
       & > .col--bottom {
        vertical-align: bottom;
       }
    }

    .grid--table {
        display: table;
        table-layout: fixed;
        width: 100%;
        .col & {
            //if the grid is nested reset the width
            width:auto;
        }
        & > * {
            display: table-cell;
        }
    }

        .medium-grid--table {
            @include medium {
                display: table;
                table-layout: fixed;
                width: 100%;
                .col & {
                    //if the grid is nested reset the width
                    width:auto;
                }
                & > * {
                    display: table-cell;
                }
            }
        }


// ----------------------------------------------------------------------------
// main section's vertical spacing
// ----------------------------------------------------------------------------

$section-spacing: $space-xxxxl;
$section-spacing--small: $space-m;

%section--top {
   @include rem-convert(padding-top,$section-spacing);
    & {
        @include smallish-down {
            @include rem-convert(padding-top,$section-spacing--small);
        }
    }

    &.-more-space {
        @include rem-convert(padding-top, 50);
        & {
            @include smallish-down {
                @include rem-convert(padding-top,$section-spacing--small * 2);
            }
        }
    }
}

%section--bottom {
   @include rem-convert(padding-bottom,$section-spacing);
    & {
        @include smallish-down {
            @include rem-convert(padding-bottom,$section-spacing--small);
        }
    }
    &.-more-space {
        @include rem-convert(padding-bottom, 50);
        & {
            @include smallish-down {
                @include rem-convert(padding-bottom,$section-spacing--small * 2);
            }
        }
    }
}

.section,
.section--trailing-break,
.section--leading-break {
    @extend %section--bottom;
    @extend %section--top;
    @extend %content-block;
}

    /* section modifiers */
    .section--top {
        @extend %section--top;
    }

    .section--bottom {
         @extend %section--bottom;
    }
    
    .block-wrapper {
        padding-left: 1.875rem;
        padding-right: 1.875rem;

        @include medium {
            padding-left: 4rem;
            padding-right: 4rem;
        }
        @include large {
            padding-left: 1.875rem;
            padding-right: 1.875rem;
        }
    }

// ----------------------------------------------------------------------------
// Line breaks
// ----------------------------------------------------------------------------
// do we actually need a breakline that is not used by its self ..? (I can't think of any circumstance)
%breakline {
    position:relative;
    &:after {
        content: "";
        width: 15%;
        background-color: #EB9C1A;
        height: 3px;
        margin: 0;
        padding: 0;
        display: block;
        position: absolute;
    }
}

%leading-break {
    &:after {
        top:0;
    }
}

%trailing-break {
    &:after {
        bottom:0;
    }
}

.leading-break,
.trailing-break,
.section--trailing-break,
.section--leading-break {
    @extend %breakline;
}

.leading-break,
.section--leading-break {
    @extend %leading-break;
}

.trailing-break,
.section--trailing-break {
    @extend %trailing-break;
}
// ----------------------------------------------------------------------------
// widths
// ----------------------------------------------------------------------------

.tenth { width: 10% }
.sixth { width: 16.666666666% }
.quarter { width: 25% }
.fifth { width: 20% }
.third { width: 33.333333333% }
.three-tenths { width: 30% }
.two-fifths { width: 40% }
.half { width: 50% }
.three-fifths { width: 60% }
.two-thirds { width: 66.666666666% }
.seven-tenths { width: 70% }
.three-quarters { width: 75% }
.four-fifths { width: 80% }
.nine-tenths { width: 90% }
.full { width: 100% }

//xsmall only
@include small-down {
    .xsmall-tenth { width: 10% }
    .xsmall-sixth { width: 16.666666666% }
    .xsmall-quarter { width: 25% }
    .xsmall-fifth { width: 20% }
    .xsmall-third { width: 33.333333333% }
    .xsmall-three-tenths { width: 30% }
    .xsmall-two-fifths { width: 40% }
    .xsmall-half { width: 50% }
    .xsmall-three-fifths { width: 60% }
    .xsmall-two-thirds { width: 66.666666666% }
    .xsmall-seven-tenths { width: 70% }
    .xsmall-three-quarters { width: 75% }
    .xsmall-four-fifths { width: 80% }
    .xsmall-nine-tenths { width: 90% }
    .xsmall-full { width: 100% }
}

//small only
@include smallish-down {
    .small-tenth { width: 10% }
    .small-sixth { width: 16.666666666% }
    .small-quarter { width: 25% }
    .small-fifth { width: 20% }
    .small-third { width: 33.333333333% }
    .small-three-tenths { width: 30% }
    .small-two-fifths { width: 40% }
    .small-half { width: 50% }
    .small-three-fifths { width: 60% }
    .small-two-thirds { width: 66.666666666% }
    .small-seven-tenths { width: 70% }
    .small-three-quarters { width: 75% }
    .small-four-fifths { width: 80% }
    .small-nine-tenths { width: 90% }
    .small-full { width: 100% }
}

//small up
@include smallish {
    .smallish-tenth { width: 10% }
    .smallish-sixth { width: 16.666666666% }
    .smallish-quarter { width: 25% }
    .smallish-fifth { width: 20% }
    .smallish-third { width: 33.333333333% }
    .smallish-three-tenths { width: 30% }
    .smallish-two-fifths { width: 40% }
    .smallish-half { width: 50% }
    .smallish-three-fifths { width: 60% }
    .smallish-two-thirds { width: 66.666666666% }
    .smallish-seven-tenths { width: 70% }
    .smallish-three-quarters { width: 75% }
    .smallish-four-fifths { width: 80% }
    .smallish-nine-tenths { width: 90% }
    .smallish-full { width: 100% }
}

// medium down
@include medium-down {
    .medium-down-full { width: 100% }
}

//medium up
@include medium {
    .medium-tenth { width: 10% }
    .medium-sixth { width: 16.666666666% }
    .medium-quarter { width: 25% }
    .medium-fifth { width: 20% }
    .medium-third { width: 33.333333333% }
    .medium-three-tenths { width: 30% }
    .medium-two-fifths { width: 40% }
    .medium-half { width: 50% }
    .medium-three-fifths { width: 60% }
    .medium-two-thirds { width: 66.666666666% }
    .medium-seven-tenths { width: 70% }
    .medium-three-quarters { width: 75% }
    .medium-four-fifths { width: 80% }
    .medium-nine-tenths { width: 90% }
    .medium-full { width: 100% }
}

//large up
@include large {
    .large-tenth { width: 10% }
    .large-sixth { width: 16.666666666% }
    .large-15 { width: 15% }
    .large-quarter { width: 25% }
    .large-fifth { width: 20% }
    .large-third { width: 33.333333333% }
    .large-three-tenths { width: 30% }
    .large-two-fifths { width: 40% }
    .large-half { width: 50% }
    .large-three-fifths { width: 60% }
    .large-two-thirds { width: 66.666666666% }
    .large-seven-tenths { width: 70% }
    .large-three-quarters { width: 75% }
    .large-four-fifths { width: 80% }
    .large-nine-tenths { width: 90% }
    .large-full { width: 100% }
}

//todo check to see if i need this..
@include xlarge {
    .xlarge-tenth { width: 10% }
    .xlarge-sixth { width: 16.666666666% }
    .xlarge-quarter { width: 25% }
    .xlarge-fifth { width: 20% }
    .xlarge-third { width: 33.333333333% }
    .xlarge-three-tenths { width: 30% }
    .xlarge-two-fifths { width: 40% }
    .xlarge-half { width: 50% }
    .xlarge-three-fifths { width: 60% }
    .xlarge-two-thirds { width: 66.666666666% }
    .xlarge-seven-tenths { width: 70% }
    .xlarge-three-quarters { width: 75% }
    .xlarge-four-fifths { width: 80% }
    .xlarge-nine-tenths { width: 90% }
    .xlarge-full { width: 100% }
}
