h1, h2, h3, h4, h5, h6, p, li, code, .intro, ul, li, a.btn, .block-item {
    margin-top: 0.75em;
    margin-bottom: 0;

    &:first-child,
    h1 + &,
    h2 + &,
    h3 + &,
    h4 + &,
    h5 + &,
    h6 + & {
	    margin-top: 0;
    }

}

h1, h2, h3, h4, h5, h6 {
	clear: both; // stops weirdness where blockquotes and such are floated
}


// ----------------------------------------------------------------------------
// Heading styles
// ----------------------------------------------------------------------------

h2, h3, h4, .h4 {
    color: $dark-grey;
}

h5, h6 {
	color: inherit;
}

// ----------------------------------------------------------------------------
// Type sizes and Heirarchy
// ----------------------------------------------------------------------------

h1,
.h1 {
    text-transform: uppercase;
    @include rem-convert('font-size', 30 );
    line-height: 1.1;
    margin-bottom: 0.5em;

    @include small {
        & {
            @include rem-convert('font-size',  36 );
        }
    }

    @include smallish {
        & {
            @include rem-convert('font-size',  40 );
        }
    }

    @include medium {
        & {
            @include rem-convert('font-size',  52 );
        }
    }

    @include large {
        & {
            @include rem-convert('font-size', 64);
            line-height: 1.05;
        }
    }


    color: $dark-grey;
    font-weight: 900;
}

h2,
.h2  {

    @include rem-convert('font-size', 32);
    margin-top: 1.25em;
    margin-bottom: 0.3em;
    line-height: 1.3;

    @include smallish-down {
        @include rem-convert('font-size', 28);
    }

    color: $orange;
}

h3,
.h3 {
    @include rem-convert('font-size', 24);
    line-height: 1.3;
    margin-bottom: 0.3em;

    @include smallish-down {

    }
}

h4,
.h4 {
    @include rem-convert('font-size', 21);
    line-height: 1.3;
    margin-top: 1em;
    margin-bottom: 0.3em;
    font-weight: bold;

    @include smallish-down {

    }
}

.rich-text + .rich-text {
    // Make sure when rich text blocks are stacked the h2s on subsequent blocks get proper margin.
    h2 {
        margin-top: 1.25em;
    }
}

.intro .rich-text > *,
.intro > *,
.intro {

    @include rem-convert('font-size', 19);

    @include medium {
        @include rem-convert('font-size', 21);
    }

    @include xlarge {
        @include rem-convert('font-size', 23);
    }


    @include rem-convert(margin-bottom,$space-m);


    @include smallish-down {
        @include rem-convert('margin-bottom', $space-s);
    }

}

h5 {
    @include rem-convert('font-size', $font-size-m);
	text-transform: uppercase;
	letter-spacing: 0.03em;
	font-weight: 600;

}
//every h5 needs to have this class on it to give it a margin-botom, preferably we should put margin bottom on the h5 !
.section__sub-heading {
    @include rem-convert(margin-bottom, $space-m);
}
 h6 {
    @include rem-convert('font-size', $font-size-m);
	margin-bottom: 0.4em;
}

h6 {
	font-weight: normal;
	line-height: 1.3;
}

.intro {
    &:last-child {
        @include rem-convert(margin-bottom,$space-xxxl);
    }
}

.small {
    @include rem-convert('font-size', 14);
    @include medium {
        @include rem-convert('font-size', 15);
    }
    @include xlarge {
        @include rem-convert('font-size', 16);
    }
}

.tiny {
    @include rem-convert('font-size', 12);
}

.big-bold-italic {

    &,
    & p {
        @include rem-convert('font-size', 20);
        line-height: 1.1;
        font-weight: 900;
        font-style: italic ;

        @include smallish {
            @include rem-convert('font-size', 24);
            line-height: 1.1;
        }

        @include medium {
            @include rem-convert('font-size', 28);
            line-height: 1.1;
        }

        @include large {
            @include rem-convert('font-size', 34);
            line-height: 1.1;
        }

    }

}

.little-label,
.little-label > * {
    @include rem-convert('font-size', 12);
    line-height: 1.2;
    text-transform: uppercase;
    color: $mid-grey;
}

p, .text, code, ul, ol, td, dl, li, .btn {

    @include medium {
        @include rem-convert('font-size', 17);
    }

    @include xlarge {
        @include rem-convert('font-size', 18);
    }
}


//this causes a few issues when there is a grid in
//place hence modifier below, personally with margin top
//lots of adjacent selectors and stuff become hard to
//wrangle without adding a margin top class to every
//new element we create.

li {
    @include rem-convert(margin-top,$space-xs);
    margin-bottom: 0;
}

.list--grid {
    @include rem-convert(margin-bottom,$space-xs);
    margin-top: 0;
}

// ----------------------------------------------------------------------------
// Type weights #todo check how type kit renders font weights
// ----------------------------------------------------------------------------


p,
.text,
code,
dt,
dd,
td {
  font-weight:normal;
}

strong,
b,
th,
label {
    font-weight:bold;
}

.intro {
   font-weight:400;
}

// ----------------------------------------------------------------------------
// Typography elements
// ----------------------------------------------------------------------------

blockquote {

    display: block;
    padding:0;
    font-weight: bold;

    @include rem-convert('font-size', $font-size-l);

    line-height: 1.4;

    margin: 1em 0;


    @include medium {
        width:50%;
        margin-top:0;
        @include rem-convert('margin-bottom', $space-xl);
        @include rem-convert('margin-left', $space-xxxxl);
		float:right;
    }

    @include xlarge {
     	width:35%;
    }




    cite {
        display: block;
        font-weight: 300;
        color: $mid-grey;
        @include rem-convert('font-size', $font-size-s);
        @include rem-convert('margin-top', $space-s);
        font-style: normal;
    }

}

.inline-blockquote blockquote {
    width: 100%;
    margin-top: 2rem;
}

hr {
    @include rem-convert('margin-top',$space-xxl);
    @include rem-convert('margin-bottom',$space-xxl);
    height: 1px;
    border: none;
    background-color: #ddd;
}

// ----------------------------------------------------------------------------
// Links
// ----------------------------------------------------------------------------

a {
    outline: 0;
    text-decoration: none;
    color: $base-link-color;
    word-wrap: break-word;
    &:hover {
        color: $base-link-hover-color;
        text-decoration: underline;
    }
}
