.basic-cta-block {
  .rich-text {
    a {
      color: white;
      border-bottom: 0.5px solid white;
      text-decoration: none;
      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
      &:focus-visible {
        color: $blue;
      }
    }
  }
}
