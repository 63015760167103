/***************************************************************
    Subnavigation
****************************************************************/

.subnav {
    .subnav__heading a {
        text-decoration: underline;
    }

    &:hover .subnav__heading a {
        color:$base-link-hover-color;
        text-decoration: none;
    }

    @include rem-convert('margin-bottom', $space-xxl);

    .subnav__heading {
        @include rem-convert('margin-bottom', $space-s);
    }
}
