.interest-picker-app {
    .title {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        padding: 2rem 0 1rem;
        letter-spacing: 2px;
    }

    h4 {
        color: #009EC9;
        text-decoration: underline;
        margin: 8px 0 6.3px;
    }

    p {
        color: #303334;
    }

    a:hover,
    p:hover {
        text-decoration: none;
    }

    .select-parent {
        display: block;

        select {
            text-align: left;
        }
    }

    .select-border {
        border-bottom: 3px solid #1BA9D4;
        display: inline-block;
    }

    label,
    select,
    option {
        display: inline-block;
        width: auto;
        font-size: 28px !important;
        font-weight: 800 !important;
        line-height: 1.3;
        max-width: 100%;
        background-color: #fff;
    }

    label {
        padding-top: 20px;
    }

    select {
        all: unset;
        width: 14rem;
        border: none;
        padding: .25rem 2.5rem .25rem .25rem;
        border-radius: .5em;
        color: #009EC9;
        // base64 version of svg/core/caret-down-blue.svg
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 1.5L10 9.5L18 1.5' stroke='%231BA9D4' stroke-width='3'/%3E%3C/svg%3E");
        background-color: white;
        background-repeat: no-repeat;
        background-position: 100% 55%;
    }

    @media only screen and (min-device-width: 375px) {
        // 14rem/260px is the min size to fit the text, but 210px is the most that can tidily fit on iPhone 5
        select {
            width: 17rem;
            // max-width is used by Firefox
            max-width: 17rem;
        }
    }

    .select-inputs {
        margin-bottom: 1.5rem;

        span:first-of-type {
            margin-bottom: .5rem;
        }
    }

    .other-results {
         a {
             display: block;
         }

        img {
            width: 100%;
        }
    }

    @include medium {
        .title {
            padding: 2rem 0;
            font-size: 14px;
        }

        .select-inputs {
            text-align: center;
            margin: 0;

            span {
                margin: 0;
            }
        }

        label {
            padding-top: 40px;
        }

        .main-result {
            display: flex;
            margin: 0 auto 1.6rem;

            h2,
            h4,
            p {
                margin-left: 2rem;
                margin-right: 2rem;
            }

            h2 {
                color: black;
            }

            img {
                width: 50%;
            }

            >div {
                margin: auto;
                width: 50%;
            }

            h4 {
                text-decoration: none;
                font-weight: normal;
            }
        }

        .select-parent {
            display: inline;
           &:first-of-type {
                margin-right: 1rem;
           }
        }

        label {
            padding-top: 0;
        }

        .other-results {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            a {
                display: block;
                width: calc(50% - 1rem);
            }
        }
    }

    @include large {
        .main-result {
            h2,
            h4,
            p {
                margin-left: 4rem;
                margin-right: 4rem;
            }
        }

        .other-results {
            a {
                width: calc(25% - 1.5rem);
            }

            &.with-selected-item {
                a {
                    width: calc(33% - 1rem);
                }
            }
        }
    }
}
