.page-catagory__container {
}

	.page-catagory__list {
		display: flex;
	    list-style: none;
	    padding: 0;
	    flex-wrap: wrap;
	}

	.page-catagory__list-item.page-catagory__list-item {
		margin: 0.5rem 1rem 0 0;
	    background: $blue;
	    padding: 0.75rem;

	    & > a {
			color: #fff;
	    }
	}

