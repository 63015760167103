
@media print {
    body, h1, h2, h3, ol, ul, div {     width: auto;     border: 0;     margin: 0;     padding: 0;     float: none;     position: static; }
    * {  overflow: visible !important; background: transparent !important; color: black !important; text-shadow: none !important; filter:none !important; -ms-filter: none !important; float: none;  page-break-after: avoid; } /* Black prints faster: h5bp.com/s */
    a, a:visited { text-decoration: underline; }
    a[href]:after { content: " (" attr(href) ")"; font-size: 90%; }
    abbr[title]:after { content: " (" attr(title) ")"; }
    .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after { content: ""; }  /* Don't show links for images, or javascript/internal links */
    pre, blockquote { page-break-inside: avoid; }
    thead { display: table-header-group; } /* h5bp.com/t */
    tr, img { page-break-inside: avoid; }
    img { max-width: 100% !important; }
    @page { margin: 0.5cm; }
    p, h2, h3 { orphans: 3; widows: 3; }
    h2, h3 { page-break-after: avoid; }
    body {
        margin: 0 0;
        padding:0;
        line-height: 1.4em;
        word-spacing:1px;
        letter-spacing:0.2px;
        font: 13px Arial, Helvetica,"Lucida Grande", serif;
    }
    ul {
        list-style-type: decimal!important;
    }
    h1 {

        font-size:28px!important;
    }
    h2 {
        font-size:22px!important;
    }
    h3 {
        font-size: 20px!important;
    }
    h4 {
        font-size: 18px!important;
    }
    h5, h6 {
        font-size: 16px!important;
    }
    h2, h3 {
       page-break-after: avoid;
    }
    .btn {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .bcito-main-content {
        width: auto !important;
        overflow: visible !important;
        display: inline;
        margin: 0;

        * {
            overflow: visible !important;
        }
    }
    .intro {
         font-size:15px;

         .rich-text {
            margin: 0 !important;
         }
    }

    .col .rich-text {
        margin: 0 !important;
        display: inline !important;
        overflow: visible !important;
    }

    .grid,
    .grid--internal,
    .gutters {
        overflow: visible !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: none !important;
        width: auto !important;
    }

    .gutters {
        padding-left: 2em !important;
        padding-right: 2em !important;

        > .section {
            border: 0 !important;
        }
    }

    .col {
        width: auto !important;
        float:none!important;
        height: auto;
        padding-left: 0 !important;
        padding-right: 0 !important;
        overflow: visible !important;
    }

    .banner,
    .section,
    .section--trailing-break,
    .section--leading-break {
        border-bottom:1px solid #000;
        &:before,
        &:after {
            content:"";
            height:0px!important;
            background-color:transparent;
            width:0%!important;
        }

        page-break-after: avoid;
    }

    .section--leading-break {
        &:first-child {
            padding-top: 0 !important;
        }
    }

    .banner {
        margin-left: 2em !important;
        margin-right: 2em !important;

        .gutters {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .leading-break {
        &:after {
            display: none !important;
        }
    }

    .banner__image,
    .banner__pattern {
        display: none !important;
    }

    .global-nav,
    #footer,
    .hide-on-print,
    .video-snippet-container,
    .news-report,
    img {
         display:none!important;
    }

     blockquote {
         width:100%;
         font-size:14px!important;
    }
    .theme--promo {
        display:none;
    }
    .list--grid {padding-left:0!important;}

    .list-styled {
        padding-left: 16px !important;
    }

    #djDebugToolbarHandle {
        display: none !important;
    }

    .theme--primary {
        display: inline !important;
        overflow: visible !important;
        height: 100%;
    }

}
@page {
    size: auto;   /* auto is the initial value */
    margin: 10% 5%;
}