.u-mt--medium {
  margin-top: 1.5rem!important;
}

.u-m-reset {
  margin: 0 !important;
}

.u-mt-reset {
  margin: 0 !important;
}

.u-mtop-reset {
  margin-top: 0 !important;
}

.u-mb-reset {
  margin-bottom: 0 !important;
}

.div-mb-reset {
  div {
    margin-bottom: 0 !important;
  }
  p {
    margin-bottom: 0 !important;
  }
}

.u-pl-reset {
  padding-left: 0 !important;
}

.u-p-medium {
  padding: 1.625rem;
}

.u-pr-xsmall {
  padding-right: 0.5rem;
}

.u-mt--xsmall {
  margin-top: 0.5rem!important;
}

.u-mb--xsmall {
  margin-bottom: 0.5rem!important;
}

.u-mt--small {
  margin-top: 1rem !important;
}

.u-mb--small {
  margin-bottom: 1rem !important;
}

.u-mx--small {
  margin: 0 1rem !important;
}

.smallish-mt--xsmall {
  @include smallish-down {
    margin-top: 0.5rem !important;
  }
}

.smallish-mt--small {
  @include smallish-down {
    margin-top: 1rem !important;
  }
}

.smallish-mt--medium {
  @include smallish-down {
    margin-top: 1.5rem !important;
  }
}

.medium-down-mt--small {
  @include medium-down {
    margin-top: 1.5rem !important;
  }
}

.medium-mt--small {
  @include medium {
    margin-top: 1.5rem !important;
  }
}

.large-mt-reset {
  @include large {
    margin-top: 0 !important;
  }
}

.md-text-align-left {
  text-align: right;
  @include medium-down {
    text-align: left;
  }
}

.smallish-align-left {
  text-align: right;
  @include smallish-down {
    text-align: left;
  }
}

.display-inline-block {
  display: inline-block;
}

////////////////////////////////////DropDown Menu
.select-dropdown,
.location-dropdown {
  font-weight: 400;
  font-size: 18px;
  color: #009DC9;
  outline: 0;
  appearance: none;
  transition: all 0.2s ease-in-out;
  position: relative;
  border-radius: 0;
  padding: 14px;
  border: solid 1px #009DC9;
  border-right: solid 50px #009DC9;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::-ms-expand {
    display: none;
  }
}

.carrot-icon {
  position: absolute;
  right: 0;
  top: 0px;
  height: 100%;
  pointer-events: none;
  height: 100%;
  padding: 17px;
  cursor: pointer;
}

.dropdown-list-item {
  padding: 10px;
  background-color: white;
  border-top: solid thin #c9c9c993;
  &:hover {
    background-color: #009DC9;
    color: white;
  }
}

.dropdown-list-options {
  display: none;
  position: absolute;
  right: 0;
  top:46px;
  border: solid thin #009DC9;
  border-top: unset;
  a {
    text-decoration: none;
  }

  a:first-child {
    border: unset;
  }
}


////////////// Orange Bar -break section

.fixed-breakline {
  position:relative;
  padding-bottom: 1rem;

  @include smallish-down {
    padding-bottom: 1rem;
    // margin-top: 1.5rem;
  }

  @include medium {
    padding-bottom: 1.5rem;
    // margin-top: 1.5rem;
  }

  @include large {
    padding-bottom: 1.5rem;
  }

  &:after {
      content: "";
      width: 100px;
      background-color: #EB9C1A;
      height: 3px;
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
  }

  @include smallish-down {
    &:after {
      width: 57px;
    }
  }
}

.fixed-leading-break {
  &:after {
      top:0;
  }
}

.u-pos-relative {
  position: relative;
}

.banner-image-fix {
  margin-left: 0;
  margin-right: 0;
  width:100%;
}

.all-stories {
  display: none;
}

.onload-stories {
  display: none;
}
