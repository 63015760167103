
figure {
    margin:0;
}

.richtext-image {
    @include rem-convert(margin-top, $space-m);
    //border-top: 4px solid $base-link-color;

    &.left {
        @include rem-convert(margin-right, $space-l);
        float:left;

    }
    &.right {
        @include rem-convert(margin-left, $space-l);
        float:right;
        border-top: 4px solid $base-link-color;
    }
    &.left,
    &.right {
        height:auto;
        max-width:50%;
    }

    &.left,
    &.right,
    &.full-width {
        @include rem-convert(margin-bottom, $space-l);
    }

    &.full-width {
       max-width: 100%;
       height: auto;
    }

}
