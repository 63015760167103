@use 'sass:math';

// ----------------------------------------------------------------------------
// Typography
// ----------------------------------------------------------------------------
//condensed

$sans-serif-stack: "proxima-nova", sans-serif;

$base-line-height: 1.5;


// Specify some font sizes to get your project rolling. These are predominantly
// used by _typography.scss

$font-size-xxxxl:       64; // big statistics figures and the like
$font-size-xxxl:        48; // h1
$font-size-xxl:         32; // h2
$font-size-xl:          24; // h3
$font-size-l:           20; // h4
$font-size-m:           16; // p, h5, h6
$font-size-s:           14; // small
$font-size-xs:          12; // x-small

$line-height-xxxxl:     math.div(88, 64); // big statistics figures and the like
$line-height-xxxl:      math.div(56, 48); // h1
$line-height-xxl:       math.div(40, 32); // h2
$line-height-xl:        math.div(36, 24); // h3
$line-height-l:         math.div(28, 20); // h4
$line-height-m:         math.div(24, 16); // p, h5, h6
$line-height-s:         math.div(20, 14); // small
$line-height-xs:        math.div(16, 12); // x-small

// -----------------------------------------------------------------------------
// Fonts
// -----------------------------------------------------------------------------

$font-light:    300;
$font-normal:   400;
$font-bold:     600;

// -----------------------------------------------------------------------------
// Media queries
// -----------------------------------------------------------------------------

$breakpoint-xsmall:     20em;   // 320px
$breakpoint-small:      25em;   // 400px
$breakpoint-smallish:   29.5em;   // 470px ish
$breakpoint-medium:     45em;   // 600px
$breakpoint-large:      59em;   // 930px ish
$breakpoint-xlarge:     75em;   // 1200px
$breakpoint-xxlarge:    87em;   // 1400px

$breakpoint-x-small-max:    $breakpoint-small - 0.1;
$breakpoint-small-max:      $breakpoint-smallish - 0.1;
$breakpoint-smallish-max:   $breakpoint-medium - 0.1;
$breakpoint-medium-max:     $breakpoint-large - 0.1;
$breakpoint-large-max:      $breakpoint-xlarge - 0.1;


// Media shortcuts
@mixin xsmall() {
    @media only screen and (max-width:#{$breakpoint-x-small-max}) {
        @content;
    }
}

@mixin small-down() {
    @media only screen and (max-width: #{$breakpoint-small-max} ) {
        @content;
    }
}

@mixin small() {
    @media only screen and (min-width: #{$breakpoint-small} ) {
        @content;
    }
}

@mixin smallish-down() {
    @media only screen and (max-width: #{$breakpoint-smallish-max} ) {
        @content;
    }
}

@mixin smallish() {
    @media only screen and (min-width: #{$breakpoint-smallish} ) {
        @content;
    }
}

@mixin smallish-only() {
    @media only screen and (min-width: #{$breakpoint-smallish}) and (max-width: #{$breakpoint-smallish-max})  {
        @content;
    }
}

@mixin medium-down() {
    @media only screen and (max-width: #{$breakpoint-medium-max}) {
        @content;
    }
}

@mixin medium() {
    @media only screen and (min-width: #{$breakpoint-medium}) {
        @content;
    }
}

@mixin medium-only {
    @media only screen and (min-width: #{$breakpoint-medium}) and (max-width: #{$breakpoint-large-max})  {
        @content;
    }
}

@mixin large() {
    @media only screen and (min-width: #{$breakpoint-large}) {
        @content;
    }
}

@mixin xlarge() {
    @media only screen and (min-width: #{$breakpoint-xlarge}) {
        @content;
    }
}

@mixin xxlarge() {
    @media only screen and (min-width: #{$breakpoint-xxlarge}) {
        @content;
    }
}

// ----------------------------------------------------------------------------
// Spacing
// ----------------------------------------------------------------------------

$space: 16; //px

$space-0: 0;
$space-xxs:     $space * .25;   //4px
$space-xs:      $space * .5;    //8px
$space-s:       $space * .75;   //12px
$space-m:       $space;         //16px
$space-l:       $space * 1.25;  //20px
$space-xl:      $space * 1.5;   //24px
$space-xxl:     $space * 1.75;  //28px
$space-xxxl:    $space * 2;     //32px
$space-xxxxl:   $space * 2.5;     //40px


// ----------------------------------------------------------------------------
// Colours
// ----------------------------------------------------------------------------
$white:         #fff;
$light-grey:    #E8EBED;
$mid-grey:      #808587;
$dark-grey:     #303334;
$light-orange:  #FEF8E8;
//$orange:        #EB9C1A;
$orange:        #F29130;
//$blue:          #269FD4;
$blue:          #009EC9;
$dark-blue:		#042432;
$green:			#56A43B;

$tepukenga-light-green: #3bad5f;
$tepukenga-mid-green: #216e31;
$tepukenga-dark-green: #183121;


/* base colours */

$base-font-color: #424749;
$base-bg-color: #F7F8F5; // #F7F8F5;

/* heading colours */
$primary-heading-color: $orange;
$primary-border-color: $light-grey;


/* link colours */
$base-link-color: $blue;
$base-link-hover-color: darken($base-link-color, 10%);
$base-link-visited-color: lighten($base-link-color, 5%); //#c3c;


$form-error:#F24949;
