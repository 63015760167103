$tepukenga-primary: #000;
$tepukenga-secondary: #216e31; // Mid green

.tepukenga {
	.tepukenga__wrapper {
		position: relative;

		img {
			image-rendering: -webkit-optimize-contrast;
		}

		p {
			margin-top: 0;
			font-size: 0.88em;
		}

		.tepukenga__tagline-text {
			padding-bottom: 8px;
		}

		a {
			color: #fff;
			&.tepukenga__learn-more-link {
				display: block;
				margin-top: 8px;
			}
		}

		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			@media screen and (max-width: 719px) {
				display: block;
				margin-bottom: 32px;
			}

			&.tepukenga__bottom-links--desktop {
				@media screen and (max-width: 719px) { display: none; }
			}
			&.tepukenga__bottom-links--mobile {
				@media screen and (min-width: 720px) { display: none; }
			}

			li {
				margin: 0 16px 0 0;
				@media screen and (max-width: 719px) {
					margin: 0 0 16px 0;
				}

				a {
					text-decoration: underline;
					font-size: 0.66em;

					&:hover, &:focus, &:active {
						text-decoration: none;
					}
				}
			}
		}

		.tepukenga__logo {
			display: block;
			margin-bottom: 8px;

			img {
				max-width: 200px;
			}
		}

		.tepukenga__tagline-wrapper {
			margin: 16px 0 8px;

			p, a {
				display: inline-block;
				margin-right: 16px;
			}
			p {
				@media screen and (max-width: 719px) {
					display: block;
					margin-bottom: 32px;
				}
			}
		}

		.tepukenga__btn {
			background-color: $tepukenga-secondary;
			padding: 8px 16px;
			margin-bottom: 16px;
		}

		.tepukenga__right-content {
			@media screen and (min-width: 720px) {
				position: absolute;
				bottom: 0;
				text-align: right;
			}
			.tepukenga__logo-nzgovt { max-width: 250px; }

			.tepukenga__logo-nzgovt--desktop {
				margin-bottom: 8px;
				display: block;
				@media screen and (max-width: 719px) { display: none; }
			}
			.tepukenga__logo-nzgovt--mobile {
				display: block;
				margin-bottom: 32px;
				@media screen and (min-width: 720px) { display: none; }
			}
			.tepukenga__copyright {
				font-size: 0.66em;
			}
		}
	}
}
