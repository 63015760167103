/***************************************************************
   Banners sectin page and homepage
****************************************************************/

$banner-padding:        4%;
$banner-padding-small:  8%;

.banner {
    @extend %clearfix;
    overflow:hidden;
    position: relative;
    padding-top: $banner-padding;
    padding-bottom: $banner-padding;
    &.campaign {
        padding-bottom: 0;
        padding-top: 0;
        height: max(360px, min(50vw, 680px));
    }
    @include smallish-down {
	    padding-top: $banner-padding-small;
	    padding-bottom: $banner-padding-small;
    }
    @include small-down {
        padding-bottom:0; /// lose padding when image stacks under the text stuff
        //for use on section template
        .video-snippet-container {
            padding-bottom: $banner-padding-small;
        }
    }
}

.banner-with-logo {
    @extend %clearfix;
    overflow:hidden;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;

    @include smallish-down {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-bottom:0; /// lose padding when image stacks under the text stuff
    }
}

.banner--centered-content {
    position: absolute;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 999;
    @include small-down {
        width: 90%;
        h1 {
            @include rem-convert('font-size', 20);
        }
    }
}

// adjusts women in trades banner according to each breakpoint
.women-in-trades-banner-height {

    @include smallish-down {
      height: 230px;
    }

    @include medium {
      height: 300px;
    }

    @include large {
      height: 400px;
    }
  }

    .banner__text,
    .banner__text-left,
    .banner__extra {
        position: relative;
        z-index: 100;
    }

    .banner__text {
        @include small-down {
            padding-bottom: 0;
            text-align: center;
        }
    }

    .banner__text-left {
        @include small-down {
            padding-bottom: 0;
            text-align: left;
        }
    }

	.banner__title {

	    @include smallish-down {
	        & {  @include rem-convert('font-size',  30.4 ); }
        }

	    @include medium {
	        & {  @include rem-convert('font-size',  44 ); }
	    }

	    @include large {
	        & {  @include rem-convert('font-size', 64); }
	    }

	    .template-bcito-home-page & {
		    @include large {
		        & {  @include rem-convert('font-size', 56); }
		    }
	    }

        a {
            text-decoration: none;
        }
    }


    .banner__pattern {
        overflow:hidden;
        @extend %absolute-fullscreen;

        &:before {
            @extend %svg-pattern-base;
        }
    }

    .banner__image {
        @extend %absolute-fullscreen;
        &.campaign {
            height: 100%;
        }
        .campaign_block {
            height: max(360px, min(50vw, 680px));
            &.static_banner {
               height: max(400px, min(50vw, 720px));
            }
            .video-snippet__play-icon-container {
                .i {
                    width: 5rem;
                    height: 5rem;
                }
            }
            .campaign__play_button {
                position: absolute;
                top: 50%;
                left: 50%;
            }
            .campaign__image {
                position: relative;
                object-fit: cover;
                width: 100%;
                height: 100%;
                // Desktop banner
                &.desktop_banner {
                    display: block; // Show by default on larger screens
                    @include small-down {
                        display: none; // Hide on mobile
                    }
                }

                // Mobile banner
                &.mobile_banner {
                    display: none; // Hide by default
                    @include small-down {
                        display: block; // Show on mobile
                        position: relative;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        & > img {
            position: absolute;
            bottom: 0;
            height: 95%;
            max-height: 500px;
            width: auto;

            @include small-down {
                margin:0 auto!important;
                width:65%;
                height:auto;
                display:block;
            }
        }

        &:before,
        &:after {
            @extend %svg-pattern-base;
        }
        &:after {
            @include smallish-down {
                bottom:0;
                & > img {
                    margin:0;
                    transform:auto;
                }
            }
        }

        @include small-down {
            position:relative;
            & > img {
                position:relative;
            }
        }
        @include smallish-down {

            margin-left: -1em;
            margin-right: -1em;
            width:110%;

        }
    }


    /* banner theming */

    .banner--darkgrey {

        background-color:#323232;
        color:$white;

        .banner__title,
        .banner__title a { color:$white; }

        @include smallish-down {
            &:hover .banner__title a,
            &:hover .banner__title {  color:darken($white, 7%); }

            .banner__extra { border-top: 1px solid rgba(255,255,255,0.2); }
        }
    }

    .banner--lightgrey {
        background-color:#E8EDEF;

        .banner__title a {
            color:$dark-grey;
        }

        @include smallish-down {
            &:hover .banner__title a,
            &:hover .banner__title {  color:black; }
        }
    }

    .banner--image-left {

        .banner__text,
        .banner__text-left,
        .banner__extra {
            float:right;
        }

        // Push the image out of the way of the text block(s)
        .banner__image > img {

            right: 67%;

            @include medium-down {
                right: 64%;
            }
            @include smallish-down {
                right: 68%;
            }
            @include small-down {
                right: auto; // it's centered now
            }
        }

        .banner__pattern:before {
            @include svgpoly(000000,0.2, 100, 0, 40, 0, 20, 100, 100, 100 );

			@include smallish-down {
				right: -30%; // Pull it to the centre on mobile;
			}
        }

        .banner__image:after {
            @include svgpoly(000000,0.3, 100, 100, 100, 90, 0, 60, 0, 100 );
        }
	}

    .banner--image-right {
        // Push the image out of the way of the text block(s)
        //to check this when preview is working
        .banner__image > img {

            left: 70%;

            @include medium-down {
                left: 64%;
            }
            @include smallish-down {
                left: 67%;
            }
            @include small-down {
                left: auto; // it's centered now
            }
        }
        .banner__pattern:before {
             @include svgpoly(041821,0.06, 90, 100, 20, 0, 0, 0, 0, 100 );
        }
        .banner__image:after {
            @include svgpoly(041821,0.08, 100, 100, 100, 20, 70, 100, 70, 100 );
    	}
    }

// Open Polytechnic dynamic banners
#open-poly-module-1 {
    button {
        overflow: inherit;
        white-space: normal;
        height: auto;
        text-overflow: inherit;
    }
}

.campaign-banner--image,
.campaign-banner--mobile-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.campaign-banner--mobile-image {
    display: none;
}

.banner-wrapper {
    max-width: 1850px;
    margin: auto;
}