$spacing-media: .75em;

.media,
.media--rev,
.media--align-middle {
    @extend %clearfix;
}

.media__image {
    float: left;
    margin-right: $spacing-media;
    @include large {
        margin-right: $spacing-media * 1.5;
    }
}

.media__body {
    @extend %clearfix;
}


// Swap the image to the right
.media--rev > .media__image {
    float: right;
    margin-right: 0;
    margin-left: $spacing-media;
    @include large {
        margin-left: $spacing-media * 1.5;
    }
}

.media--rev > .media__body {
    @extend %clearfix;
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.media--align-middle {
    display: table;

    > .media__body {
        display: table-cell;
        vertical-align: middle;

        > * {
            margin: 0;
        }
    }
}

// -----------------------------------------------------------------------------
// Embeddable instagram media
// -----------------------------------------------------------------------------

.instagram-media {
    min-width: auto !important;
    margin-top: 18px !important;
    margin-bottom: 18px !important;
}