//potentially could make the body white and not have theme primary
.theme--primary { background-color:$white; }

.theme--secondary { background-color:$light-grey; }

.theme--grey { background-color: #eeeeee; }

.theme--tertiary {
    background-color:#f68b22;
    color:$white;
    a.btn {
       color:$orange;
       background-color:$white;
    }
    h1, h2, h3, h4, h5 {
        color:$white;
    }

}

.theme--darkgrey {
    background-color:#323232;
    color:$white;

    h1, h2, h3, h4, h5 {
        color:$white;
    }
}

.theme--promo {
    background-color:$green;
    color:$white;
    a {
        color:$white;
        text-decoration: underline;
    }
    h1, h2, h3, h4, h5 {
        color:$white;
    }
    &:hover {
        background-color:darken($green, 5%);

        a {
            text-decoration: none;
        }
    }
}


$themes_colors: (
        'orange': (#F38231),
        'green': (#6EBE55),
        'light-green': (lighten(#6EBE55, 40%)),
        'dark-green': (#1C650C),
        'blue': (#1BA9D4),
        'light-blue': (lighten(#1BA9D4, 45%)),
        'dark-blue': (#095576),
        'yellow': (#FBAF31),
        'red': (#ED5F3A),
        'pink': (#E189C6),
        'dark-grey': ($dark-grey),
        'dark-dark-grey': (#373A36),
        'light-grey': (#F8F8F8),
        'white': (#FFFFFF),
);


@each $alias, $colors in $themes_colors {

    $color: save-nth-list-value($colors, 1);

    .u-background-color-#{$alias} {
        background-color: $color;
    }

    .u-text-color-#{$alias} {
        color: $color;
    }

    .u-background-color-#{$alias}\:hover {
        &:hover {
            background-color: darken($color, 5%);
        }
    }

    .u-color-headings-#{$alias} {
        h1, h2, h3, h4, h5 {
            color: $color;
        }
    }
}

.u-color-white {
    color: $white;
}

.u-bg-color-dark-grey {
    background-color: #323232;
}

.u-color-orange {
    color: $orange;
}

.u-color-black {
    color: #303334;
}

.theme--grey-color {
    h1,h2,h3,h4,h5,h6 {
      color: #303334;
    }
  }

.theme--primary-color {
    h1,h2,h3,h4,h5,h6 {
      color: #303334;
    }
  }

.theme--darkgrey-color {
    h1,h2,h3,h4,h5,h6 {
      color: $white !important;
    }
    ul {
        list-style: none;
    }

    ul li::before {
    content: "\2022";
    color: $white !important;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    }
}

.linkable-story {
      a {
          color: #009EC9 !important;
          text-decoration: underline !important;
      }
      h4 {
          color: #009EC9;
      }
}

.white-underline-link {
    padding-left: 20px;
    color: $white;
    text-decoration: underline;
    &:hover {
        color: $white;
    }
    @include large {
        padding-left: 40px;
    }
}

.orange-bg-block {
    h1,h2,h3,h4,h5,h6 {
        color: $white;
    }
    background-color: $orange;
    color: $white;
    a {
        color: $white;
        text-decoration: underline;
    }
}

// This section will style the background color and button color for the call to action block
.block-background--orange {
    background-color: $orange;
    color: $white;
    .btn--white {
        color: $orange;
        margin-top: 0 !important;
        &:hover {
            color: $orange;
        }
    }
}

.block-background--blue {
    background-color: #009EC9;
    color: $white;
    .btn--white {
        color: #009EC9;
        margin-top: 0 !important;
        &:hover {
            color: #009EC9;
        }
    }
}

.block-background--green {
    background-color: $tepukenga-mid-green;
    color: $white;
    .btn--white {
        color: $tepukenga-dark-green;
        margin-top: 0 !important;
        &:hover {
            color: $tepukenga-light-green;
        }
    }
}

// styles for Here to Help campaign custom form page
.tepukenga--gradient-bg {
  background-image: linear-gradient(to bottom, $tepukenga-light-green, $tepukenga-mid-green, $tepukenga-dark-green);
}

.u-color-tepukenga-dark-green {
    color: $tepukenga-dark-green;
}

.link--here-to-help {
    &:hover {
        color: $tepukenga-mid-green;
    }
}

.theme--tepukenga {
    h2 {
         @media only screen and (min-width: $breakpoint-large) {
            font-size: 8rem;
        }
         @media only screen and (min-width: $breakpoint-medium) {
            font-size: 6rem;
        }
    }
    .btn--primary {
        background-color: $tepukenga-mid-green;
        color: $white;
        border: $tepukenga-mid-green;
        margin-top: 0.25rem !important;

        &:hover {
            background-color: $tepukenga-dark-green;
            color: $white;
        }
    }
}






