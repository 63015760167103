/***************************************************************
    Trade tiles
****************************************************************/
 /***************************************************************
    Shadow for tiles
****************************************************************/
%tile-shadow {
    box-shadow: 0 1px 1px 1px rgba(0,0,0,0.1);
}

.trade-tile {
    @include rem-convert(margin-bottom, $space-l);
    @include rem-convert(min-height, 100);
    overflow:hidden;
    position:relative;
    display:block;
    color:$white;
    img {
        max-width:100%;
        height:100%;
        display:block;
    }

    &:hover {
		color: $white;
		.trade-tile__desc {
			padding-bottom: 2em;
		}
    }
    @extend %tile-shadow;
}

    .tile-shadow {
        @extend %tile-shadow;
    }
    .trade-tile__desc {
        @include rem-convert(padding, $space-s);
        @include rem-convert(padding-top, 0.3);
        position:absolute;
        bottom:0;
        width:100%;
        z-index: 1;
        opacity:0.9;
        background-color:$green;
        margin: 0;
        transition: padding 200ms ease-out;
        &:before {
            top: 0;
            right: 0%;
            z-index: -1;
            //makes sure the line goes to the edge when rotated
            width: 102%;
            height: 100%;
            background: inherit;
            transform: rotate(7deg);
            transform-origin: 100% 0;
            position: absolute;
            content: '';
            pointer-events: none;
        }
    }

/*--This is found on the trades page--*/
.more-trades {
    .large-two-thirds {
        position:relative;
    }

    .img--responsive {
        width:100%;
    }
    &:hover {
        .more-trades__heading a {
            color:$base-link-hover-color;
        }

        .tile--highlight {
            @include rem-convert('margin-left', -10);
            @include rem-convert('margin-right', -10);
            margin-top:1%;
            width:39%;
        }
    }
}

    .more-trades__content {
        text-align:left;
        margin-top:5%;
        margin-bottom:25%;
        display:block;
        @include small-down {
           margin-bottom:35%;
        }
        @include large {
            margin-bottom:10%;
        }
        @include large {
            margin-top:10%;
        }
    }

    .more-trades__tiles {
        overflow: hidden;
        position:absolute;
        bottom:0;
    }

        .tile {
            @extend %tile-shadow;
            z-index:1;
            position:relative;

            img {
                display:block;
            }
        }

            .tile--highlight {
                @include rem-convert('margin-left', -16);
                @include rem-convert('margin-right', -16);
                @include rem-convert('margin-bottom', -16);
                transition: all 0.1s ease;
                z-index:2;
            }
