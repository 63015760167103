
//macro styles once these have been tweaked we can add them as components

/***************************************************************
    Search results
****************************************************************/
//still to be completed
.search-results {}

    .search-results__item {
        @include rem-convert(margin-bottom, $space-xl);
        h4 {
            @include rem-convert(margin-bottom, $space-xxs);
        }
    }


/***************************************************************
    Contact snippets - tidy these up :)
****************************************************************/

.contact-offices {
    @include rem-convert(margin-top, $space-l);
}

    .contact-office,
    .office-employee {
        @include rem-convert(margin-bottom, 36);
/*
        p {
           @include rem(font-size, $font-size-s);
           line-height: $line-height-s;
        }
*/
    }

        .office-employee__name {
    	    color: $orange;
        }

	.office__streetaddress .label,
	.office__postaddress .label {
		font-weight: bold;
	}

.contact-map-link {
    color:$base-font-color;
}
/***************************************************************
    Info block
****************************************************************/

$info-block--section:32;

.info-block {
    @extend %content-block;
    @include rem-convert(padding-top, $info-block--section);
    @include rem-convert(padding-bottom, $info-block--section);
    background-color:$light-grey;

    @include smallish-down {

	   @include rem-convert(margin-right, -16);
        @include rem-convert(margin-left, -16);
        @include rem-convert(padding-right, 28);//$small--gutters + 16
        @include rem-convert(padding-left, 28);//$small--gutters + 16
    }

    //todo tidy
    .info-block__qualifications {
        @include rem-convert('margin-top', $space-s);
    }
}

    .info-block__section {

        @include rem-convert('margin-bottom', $space-xxxl);
        &:last-child {
            margin-bottom:0;
        }
    }

        .info-block__heading {
            @include rem-convert('font-size', 15);
            line-height: 1;
            color: $green;
            text-transform: uppercase;
        }


.template-qualifications-page {
    @include medium {
        h1 {
            @include rem-convert(font-size, $font-size-xxxl);
            line-height: $line-height-xxxl;
        }
    }
}
// .page-bcito-board {
//     .richtext-image {
//         max-width:20%;
//     }
// }
$section-spacing: $space-xxxxl;
$section-spacing--small: $space-m;

%section--top {

}

%section--bottom {
   @include rem-convert(padding-bottom,$section-spacing);
    & {
        @include smallish-down {
            @include rem-convert(padding-bottom,$section-spacing--small);
        }
    }
}
.section--promo {
    @include rem-convert(padding-top,$space-xxxl);
    @include rem-convert(padding-bottom,$space-xxxl);
    @include smallish-down {
        @include rem-convert(padding-top,$space-m);
        @include rem-convert(padding-bottom,$space-m);
    }
}
.section--promo-xl {
    @include rem-convert(padding-top,$space-xxxxl);
    @include rem-convert(padding-bottom,$space-xxxxl);
    @include smallish-down {
        @include rem-convert(padding-top,$space-l);
        @include rem-convert(padding-bottom,$space-l);
    }
}
// .promo-strip {
//     //position:relative;
//     //overflow:hidden;
//     .promo__pattern {
//         // &:before,
//         // &:after {
//         //     @extend %svg-pattern-base;
//         // }
//         // &:before {
//         //     @include svgpoly(000000,0.06, 60, 100, 50, 0, 0, 0, 0, 100 );
//         // }
//     }
// }

.promoblock-no-image {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    min-height: 200px;
    padding-bottom: 1.5rem;
    @include smallish-down {
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}

.trade-full-width-empty-container {
    padding-bottom: 4rem;
}

.link-benefit {
    display: inline-block;
    &:hover {
        //text-decoration: none;
        color: #000;
        //.i {
        //    background-position: 0 $icon-bg-black;
        //}
    }
}
/* hide tracking pixel img tags */
body > img {display: none;}
