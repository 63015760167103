/***************************************************************
   Buttons
****************************************************************/
/*### Form note:

Try and avoid using `<input type='submit' value='Go'>` in favour of
`<button type='submit'>Go</button>` as it's easier to style.
*/


$btn--size:40;
$btn--size--border:44;
%btn {
    //@include rem(border-radius, $space-xxs);
    padding-left: 1.5em;
    padding-right: 1.5em;
    //@include rem-convert(line-height,$btn--size);
    //@include rem-convert(height,$btn--size);
    @include rem-convert(margin-top,$space-m);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration:none;
    padding-bottom:0.5em;
    padding-top:0.5em;
    display:inline-block;
    box-sizing:border-box;
    text-align:center;
    border: 1px solid inherit;
    cursor:pointer;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    max-width: 310px;
    text-wrap: balance!important;

    &:hover {
        text-decoration: none;
    }
}


// buttons
.btn,
button {
    @extend %btn;
}

button {
    appearance: none;
    background-color: transparent;
}

.btn--block + .btn--block { margin-left:0; }
.btn--block + .btn--block {
    @include rem-convert(margin-top, $space-m);
}

/*********************************
    block modifier
********************************/

.btn--block {
    display: block;
    width: 100%;
}

.btn--block--small {
    @include small-down {
        display: block;
        width: 100%;
    }
}
.btn--block--large {
    @include large {
        display: block;
        width: 100%;
    }
}
.btn--centered {text-align:center;}

/*********************************
    Button themes
********************************/

.btn--primary {
    //@extend %font-smoothing;
    background-color: $base-link-color;
    color: $white;
    border: $base-link-color;
    &:visited { color: $white; }
    &:hover {
        background-color: darken($base-link-color, 5%);
        color: $white;
    }
    &:active {
        color:$white;
        background-color: $base-link-color;
    }
}

.btn--secondary {
    //@extend %font-smoothing;
    background-color: $orange;
    color: $white;
    border: $orange;
    &:visited { color: $white; }
    &:hover {
        background-color: darken($orange, 5%);
        color: $white;
    }
    &:active {
        color:$white;
        background-color: $orange;
    }
}

.btn--green {
    //@extend %font-smoothing;
    background-color: $green;
    color: $white;
    border: $green;
    &:visited { color: $white; }
    &:hover {
        background-color: darken($green, 5%);
        color: $white;
    }
    &:active {
        color:$white;
        background-color: $green;
    }
}
//if you need to use a input (ALTHOUGH YOU REALLY SHOULDNT)
// .btn--submit {
//     @extend %btn;
//     @include rem(line-height,$btn--size);
//     @include rem(height,$btn--size);
// }

.btn--orange {
    background-color: #FF8102;
    color: $white;
    border: #FF8102;
    margin-top: 0.25rem !important;

    &:hover {
        background-color: #e67300;
        color: $white
    }
}

.btn--white {
    background-color: $white;
    color: #303334;
    border: $white;
    margin-top: 0.25rem !important;

    &:hover {
        background-color: $white;
        color: #303334;
    }
}

.btn--green {
    background-color: $tepukenga-mid-green;
    color: $white;
    border: $tepukenga-mid-green;
    margin-top: 0.25rem !important;

    &:hover {
        background-color: $tepukenga-dark-green;
        color: $white;
    }
}

